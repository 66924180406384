import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import configureAppStore from "./store/configureStore";
import "react-toastify/dist/ReactToastify.css";
import CookieConsent from "react-cookie-consent";
import google from "./assets/images/google.jpg";
import yell from "./assets/images/yell.jpg";
import resellers from "./assets/images/resellers.jpg";
import facebook from "./assets/images/facebook.jpg";
import SlideReview from "./components/review/SlideReview";

const reviews = {
  loaded: true,
  title: "Testimonials",
  subtitle:
    "Read our reviews showcasing our customer's experience with our company, outlining our commitment to service and a happy customer. We are dedicated to customer feedback and reviews to listen to our customers and provide the best service possible.We appreciate our customers taking the time to share their experiences and look forward to working with you.",
  subdata: [
    {
      _id: 1,
      rating: 5,
      date: "04.07.2024",
      description: "5 Start Rating by Cari O.",
      icon: google,
    },
    {
      _id: 2,
      rating: 5,
      date: "04.06.2024",
      description: "5 Start Rating by Julien Sanders",
      icon: yell,
    },
    {
      _id: 3,
      rating: 5,
      date: "08.03.2024",
      description: "5 Start Rating by Alex E.",
      icon: google,
    },
    {
      _id: 4,
      rating: 5,
      date: "04.07.2024",
      description: "5 Start Rating by MarcV-22",
      icon: facebook,
    },
    {
      _id: 5,
      rating: 5,
      date: "04.07.2024",
      description: "5 Start Rating by MARCUS V.",
      icon: google,
    },
    {
      _id: 6,
      rating: 5,
      date: "04.06.2024",
      description: "5 Start Rating by Colin Thorpe",
      icon: google,
    },
    {
      _id: 7,
      rating: 5,
      date: "08.03.2024",
      description: "5 Start Rating by Paula C.",
      icon: resellers,
    },
    {
      _id: 8,
      rating: 5,
      date: "04.07.2024",
      description: "5 Start Rating by ColinT-283",
      icon: resellers,
    },
    {
      _id: 9,
      rating: 5,
      date: "04.07.2024",
      description: "5 Start Rating by Michelle G.",
      icon: resellers,
    },
    {
      _id: 10,
      rating: 5,
      date: "04.06.2024",
      description: "5 Start Rating by Colin Thorpe",
      icon: yell,
    },
    {
      _id: 11,
      rating: 5,
      date: "08.03.2024",
      description: "5 Start Rating by Dominic ..",
      icon: google,
    },
    {
      _id: 12,
      rating: 5,
      date: "04.07.2024",
      description: "5 Start Rating by Shaun Flanagan",
      icon: google,
    },
    {
      _id: 13,
      rating: 5,
      date: "04.07.2024",
      description: "5 Start Rating by Dillon B.",
      icon: google,
    },
    {
      _id: 14,
      rating: 5,
      date: "04.06.2024",
      description: "5 Start Rating by Mark Thomas",
      icon: yell,
    },
    {
      _id: 15,
      rating: 5,
      date: "08.03.2024",
      description: "5 Start Rating by Daniel G.",
      icon: yell,
    },
    {
      _id: 16,
      rating: 5,
      date: "04.07.2024",
      description: "5 Start Rating by Edward Garside",
      icon: yell,
    },
    {
      _id: 17,
      rating: 5,
      date: "04.07.2024",
      description: "5 Start Rating by Mark T.",
      icon: yell,
    },
    {
      _id: 18,
      rating: 5,
      date: "04.06.2024",
      description: "5 Start Rating by Gethin Thomas",
      icon: google,
    },
    {
      _id: 19,
      rating: 5,
      date: "08.03.2024",
      description: "5 Start Rating by Edward G.",
      icon: google,
    },
    {
      _id: 20,
      rating: 5,
      date: "04.07.2024",
      description: "5 Start Rating by Alina Seli",
      icon: facebook,
    },
    {
      _id: 21,
      rating: 5,
      date: "04.07.2024",
      description: "5 Start Rating by Simon J.",
      icon: yell,
    },
    {
      _id: 22,
      rating: 5,
      date: "04.06.2024",
      description: "5 Start Rating by Gareth Pugh",
      icon: resellers,
    },
    {
      _id: 23,
      rating: 5,
      date: "08.03.2024",
      description: "5 Start Rating by Elisa P.",
      icon: resellers,
    },
    {
      _id: 24,
      rating: 5,
      date: "04.07.2024",
      description: "5 Start Rating by Raina Brands",
      icon: resellers,
    },
    {
      _id: 25,
      rating: 5,
      date: "04.07.2024",
      description: "5 Start Rating by James Dodd",
      icon: google,
    },
    {
      _id: 26,
      rating: 5,
      date: "04.06.2024",
      description: "5 Start Rating by JamesD-1867",
      icon: google,
    },
    {
      _id: 27,
      rating: 5,
      date: "08.03.2024",
      description: "5 Start Rating by Julien S.",
      icon: resellers,
    },
    {
      _id: 28,
      rating: 5,
      date: "04.07.2024",
      description: "5 Start Rating by Vincent S.",
      icon: resellers,
    },
    {
      _id: 29,
      rating: 5,
      date: "04.07.2024",
      description: "5 Start Rating by Ylva G.",
      icon: google,
    },
    {
      _id: 30,
      rating: 5,
      date: "04.06.2024",
      description: "5 Start Rating by Finlay Coulter.",
      icon: google,
    },
  ],
};

// Custom hook to track window width
const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowWidth;
};

// Main App component
const Main = () => {
  const windowWidth = useWindowWidth();

  const cookieConsentStyle = {
    width: windowWidth < 768 ? "90%" : 350,
    maxWidth: windowWidth < 768 ? "90%" : 350,
    margin: 15,
    height: 100,
    backgroundColor: "#fff",
    border: "1px solid #B21020",
    borderRadius: 6,
    boxShadow: "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
    display: "flex",
    justifyContent: "center",
  };

  const declineButtonStyle = {
    backgroundColor: "transparent",
    textTransform: "capitalize",
    fontSize: "13px",
    paddingTop: 5,
    paddingBottom: 5,
    height: 36,
  };

  return (
    <>
      <App />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />

      <CookieConsent
        location="bottom"
        cookieName="myAwesomeCookieName2"
        style={cookieConsentStyle}
        buttonStyle={{ display: "none" }} // Hides the accept button
        declineButtonStyle={declineButtonStyle}
        expires={365}
        onDecline={() => {}}
        disableDeclineButton
        ariaDeclineLabel
      >
        <div className="relative">
          <SlideReview data={reviews} />
        </div>
      </CookieConsent>
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
const store = configureAppStore();

root.render(
  <Provider store={store}>
    <Main />
  </Provider>
);

reportWebVitals();
