import React from "react";
import { classNames } from "../helpers/classNames";
import Image from "../components/elements/Image";
import Contactimage from "../assets/images/contact.jpg";
import Input from "../components/form/Input";
import Textarea from "../components/form/Textarea";
import Button from "../components/form/Button";

const Contact = () => {
  return (
    <>
      <div
        className={classNames(
          "relative z-[2] w-full h-[200px] lg:h-[300px] xl:h-[400px] overflow-hidden rounded-b-3xl mb-10",
          "after:content-[''] after:absolute after:w-full after:h-full after:bg-gradient-to-t after:from-black/80 after:bottom-0 after:left-0 after:z-[1]"
        )}
      >
        <Image
          src={Contactimage}
          alt={""}
          width={"100%"}
          height={"100%"}
          effect={"blur"}
          className={" object-cover object-top"}
        />
        <h4 className="absolute left-0 right-0 mx-auto text-center text-white text-lg lg:text-xl xl:text-3xl capitalize bottom-20 lg:bottom:20 xl:bottom-40 z-10 font-bold">
          Contact Us
        </h4>
      </div>

      <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
        <div className="bg-[#efefef] px-10 py-16 -top-16 rounded-xl relative z-[9]">
          <div className="text-3xl text-black font-extrabold mb-5 text-center">
            Get In Touch!
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-3 items-center">
            <div className="bg-[#1e1e1e] p-6 text-center rounded-lg h-60 border border-carnation-400">
              <div className="text-center text-yellow-600 bg-white rounded-full w-16 h-16 text-xl pt-5 mx-auto">
                <i class="fa-solid fa-phone-volume"></i>
              </div>
              <div className="text-white text-xl mt-5 font-bold underline">
                Phone
              </div>
              <div className="text-white text-xl mt-2">020 7169 4208</div>
              <div className="text-white text-xl">07868 729 221</div>
            </div>
            <div className="bg-[#1e1e1e] p-6 text-center rounded-lg h-60">
              <div className="text-center text-yellow-600 bg-white rounded-full w-16 h-16 text-xl pt-5 mx-auto">
                <i class="fa-solid fa-envelope"></i>
              </div>
              <div className="text-white text-xl mt-5 font-bold underline">
                Mail
              </div>
              <div className="text-white text-xl mt-2 line-clamp-2">
                info@ashmoredecorators.co.uk
              </div>
            </div>
            <div className="bg-[#1e1e1e] p-6 text-center rounded-lg h-60">
              <div className="text-center text-yellow-600 bg-white rounded-full w-16 h-16 text-xl pt-5 mx-auto">
                <i class="fa-sharp fa-solid fa-location-dot"></i>
              </div>
              <div className="text-white text-xl mt-5 font-bold underline">
                Address
              </div>
              <div className="text-white text-xl mt-2">
                74 Ashmore Rd, London, W9 3DG
              </div>
            </div>
          </div>

          <div className="grid grid-cols-12 gap-3">
            <div className="col-span-12">
              <div className="text-3xl text-black font-extrabold mb-5 text-center mt-20">
                Have Questions? Get In Touch!
              </div>
            </div>
            <div className="col-span-12 xl:col-span-6">
              <Input
                label={"Name"}
                inputClasses={"!h-14 !mb-3 !bg-white"}
                inputPlaceholder={"Enter Your Name"}
              />
            </div>
            <div className="col-span-12 xl:col-span-6">
              <Input
                label={"Phone Number"}
                inputClasses={"!h-14 !mb-3 !bg-white"}
                inputPlaceholder={"Enter Your Valid Phone Number"}
              />
            </div>
            <div className="col-span-12">
              <Input
                label={"Email"}
                inputClasses={"!h-14 !mb-3 !bg-white"}
                inputPlaceholder={"Enter Your Email"}
              />
            </div>
            <div className="col-span-12">
              <Textarea
                label={"Message"}
                inputClasses={"!mb-3 !bg-white"}
                inputPlaceholder={"Write Your Message"}
              />
            </div>
            <div className="col-span-12">
              <Button
                buttonLabel={"Send"}
                buttonLabelClasses={"!text-lg"}
                buttonClasses={
                  "!px-4 hover:!bg-black transition-all duration-300 !rounded-md !text-sm "
                }
                buttonIcon={"fa-solid fa-paper-plane-top"}
                buttonIconPosition={"left"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="relative">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9928.911967224667!2d-0.202978!3d51.527378!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761010f83be47b%3A0x8f4310663021c6ea!2sAshmore%20Decorators%20Ltd!5e0!3m2!1sen!2sin!4v1720822698547!5m2!1sen!2sin"
          width="100%"
          height="400"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </>
  );
};

export default Contact;
