import React from "react";
import Image from "../elements/Image";
import StarRating from "./StarRating";

const CookieStory = ({ data }) => {
  return (
    <div className="w-full flex flex-col overflow-hidden transition-all duration-200 hover:shadow-lg font-bold">
      <div className="flex items-center gap-1">
        <div className="flex-grow-0 flex-shrink-0 rounded-full overflow-hidden">
          <Image src={data.icon} alt="Icon" className="w-10 h-10" />
        </div>
        <div className="w-full flex-grow flex-shrink">
          <h4 className="text-base xl:text-lg font-Lexend text-black font-semibold leading-tight">
            {data.name}
          </h4>
          <div className="text-xs xl:text-sm text-slate-600">{data.date}</div>
        </div>
        <div className="p-2 justify-end">
          <StarRating rating={data.rating} />
        </div>
      </div>

      <div className="flex text-xs xl:text-lg text-slate-800 mt-1 justify-center">
        {data.description}
      </div>
    </div>
  );
};

export default CookieStory;
