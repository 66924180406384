import React, { useEffect, useRef, useState } from "react";
import Button from "../form/Button";
import SingleStory from "./SingleStory";
import CookieStory from "./CookieStory";

const SlideReview = ({ data }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const intervalRef = useRef(null);

  const startAutoSlide = () => {
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === data.subdata.length - 1 ? 0 : prevIndex + 1
      );
    }, 2000);
  };

  const stopAutoSlide = () => {
    clearInterval(intervalRef.current);
  };

  useEffect(() => {
    startAutoSlide();
    return () => stopAutoSlide();
  }, []);

  return (
    <>
      <div className="relative flex flex-col lg:flex-row items-center z-[1]">
        {data?.subdata?.length > 0 && (
          <div className="relative w-full z-0 ">
            <CookieStory data={data.subdata[currentIndex]} />
          </div>
        )}
      </div>
    </>
  );
};

export default SlideReview;
